import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-8.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/Digitindahan`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'How To Turn Your Sari-Sari Store Into a Digitindahan',
  },
  {
    property: 'og:description',
    content:
      'Mayroon ka bang sari-sari store? Gawin mo na itong authorized payment center with Digipay!',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="How To Turn Your Sari-Sari Store Into a Digitindahan"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          HOW TO TURN YOUR SARI-SARI STORE INTO A DIGITINDAHAN
        </Heading>

        <Section mb="sp4">
          <Text>
            Instant noodles, kape, softdrinks, tinapay, candy, chips at shampoo.
            Ilan lamang ‘yan sa madalas na nabibili sa iyong sari-sari store.
            Maghihintay ka lang ng suki, siguradong may extra income ka na.
            Bukod sa hawak mo ang oras mo, nasa bahay ka lang habang kumikita.
            ‘Yan ang perks ng pagkakaroon ng sariling negosyo sa iyong home
            sweet home!
          </Text>
        </Section>
        <Section mb="sp4">
          <Text>
            Pero bakit hindi mo i-level up ang iyong tindahan?{' '}
            <span className="blog__emphasized-sentence">
              {' '}
              For just P100, pwede ka nang maging authorized payment center ng
              buong barangay!{' '}
            </span>{' '}
            Sa’yo na sila lalapit kapag bayaran na ng bills tulad ng Meralco,
            Home Credit, PAG-IBIG, credit card, at marami pang iba. Meron ding
            e-load, gaming pins, at mobile money tulad ng GCash at Paymaya. Kung
            gusto naman nila ng swak sa budget na health insurance, nandiyan ang
            HealthGuard na pwede mo ring ibenta.
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            Isa itong malaking opportunity para dumami pa ang suki mo.{' '}
            <span className="blog__emphasized-sentence">
              {' '}
              More income, more fun!{' '}
            </span>{' '}
            Bukod sa rebates na makukuha mo sa bawat transactions, maaari ka
            ding kumita kapag may kaibigan kang gumamit ng iyong referral code
            para mag-sign up bilang Digipay Agent.
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            Paano ba maging isang Digipay Agent? Simple lang ang steps!
          </Text>
          <Text>
            <ol className="blog__digipay-agent-steps">
              <li>
                Kailangan mong i-download ang Digipay app sa Playstore. Lahat ng
                iyong transactions with Digipay ay mangyayari lamang sa mismong{' '}
                <a
                  href="https://play.google.com/store/apps/details?id=com.fsgcapital.digipay&hl=en"
                  className="inline-link"
                >
                  app
                </a>
                .
              </li>
              <br />
              <li>
                I-fill out ang application form sa app or click{' '}
                <a
                  href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
                  className="inline-link"
                >
                  here
                </a>{' '}
                to sign-up. Mahalagang i-submit agad ang requirements para
                mapabilis ang pag-process ng iyong application.
              </li>
              <br />
              <li>
                Tatawag ang aming Digipay Sales team for verification. You will
                also need to deposit the payment of P100 sa kahit saang Digipay
                authorized bank accounts. Yes! Abot-kayang negosyo, ‘diba?
              </li>
            </ol>
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            Be part of the 7,500+ Digipay Agents around the Philippines. Join{' '}
            <a
              href=" https://www.facebook.com/groups/Digipayagents/"
              className="inline-link"
            >
              Digipay Agent Group
            </a>{' '}
            on Facebook para lagi kang updated sa announcements and promos.
            Huwag mo rin kalimutang i-like ang{' '}
            <a
              href="https://www.facebook.com/digipayph/"
              className="inline-link"
            >
              {' '}
              Digipay Facebook Page{' '}
            </a>
            .
          </Text>

          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
